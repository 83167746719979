<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span v-if="origin==='报名'">
                    <em>当前位置：</em>
                    <a href="javascript:;">报名管理</a>
                    <i>></i>
                    <a href="javascript:;">培训报名列表</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>
                <span v-if="origin==='渠道学员列表'">
                    <em>当前位置：</em>
                    <a href="javascript:;">渠道管理</a>
                    <i>></i>
                    <a href="javascript:;">渠道管理</a>
                    <i>></i>
                    <a href="javascript:;">查看招生情况</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员列表</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>
                <span v-if="origin==='渠道报名学员管理'">
                    <em>当前位置：</em>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;">报名列表</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>
                <span v-if="origin==='渠道学员管理'">
                    <em>当前位置：</em>
                    <a href="javascript:;">渠道管理</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>
                <span v-if="origin==='学员报名统计'">
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员报名统计</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>
                <span v-if="origin==='鉴定报名统计'">
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;">鉴定报名统计</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">学员管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员详情信息</a>
                </span>

            </div>
            <div class="framePage-body">
                <div class="framePage-body-inner">
                    <div class="framePage-body-inner-item">
                        <h3 v-if="type=='view'" class="title">培训信息</h3>
                        <h3 v-else class="title">考试信息</h3>
                        <div class="examInfo" style="padding-top:1px;">
                            <div class="personInifo-wrap">
                                <div class="label">培训名称:</div>
                                <div class="value">{{examInfo.projectName}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名工种:</div>
                                <div class="value">{{examInfo.occupationName}}</div>
                            </div>
                            <div class="personInifo-wrap" v-if="examInfo.trainLevel">
                                <div class="label">级&emsp;&emsp;别:</div>
                                <div class="value">{{examInfo.trainLevel}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名时间:</div>
                                <div class="value">{{examInfo.createTime}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">报名方式:</div>
                                <div class="value">{{$setDictionary('CT_JOIN_TYPE', examInfo.joinType)}}</div>
                            </div>
                            <div class="personInifo-wrap">
                                <div class="label">审核状态:</div>
                                <div class="value">{{$setDictionary('CT_USER_AUDIT_STATE', examInfo.auditState)}}</div>
                            </div>
                        </div>
                        <h3 class="title">个人信息</h3>
                        <div class="examInfo">
                            <div class="personInifo-wrap" v-for="item in personInfoList" :value="item.value" :key="item.value">
                                    <div class="label">{{item.label}}:</div>
                                    <div class="value">{{item.value}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="framePage-body-inner-item">
                        <h3 class="title">材料信息</h3>
                        <div class="materialInfo examInfo">
                            <block  v-for="item in materialInfoList" :key="item.value">
                                <div class="materialInfo-item">
                                    <div class="img-wrap">
                                        <el-image
                                                v-if="item.value"
                                                style="width: auto; height: 100%"
                                                :src="item.value"
                                                :preview-src-list="[item.value]">
                                        </el-image>
                                        <span v-else style="margin-top: 55px;">暂无图片</span>
                                        <!--<img :src="item.value" alt="">-->
                                    </div>
                                    <div class="text">{{item.label}}</div>
                                </div>
                            </block>
                        </div>
                    </div>
                </div>
                <div class="btn-group" v-if="origin=='鉴定报名统计'">
                  <el-button type="primary" @click="$router.back()" round>返回</el-button>
                  <el-button type="primary" round @click="jumpPrev()" v-if="prevBtnDisabled==true">上一个</el-button>
                  <el-button type="primary" round @click="jumpNext()" v-if="!nextBtnDisabled">下一个</el-button>
                </div>
                <div class="btn-group" v-if="origin!='渠道学员列表'&&origin!='鉴定报名统计'">
                    <el-button type="primary" @click="$router.back()" round>返回</el-button>
                    <!-- 仅查看不显示这些 -->
                    <template v-if="type!='view'">
                        <el-button type="primary" round @click="approved(30, false)" v-if="examInfo.auditState==20||examInfo.auditState==40">通过</el-button>
                        <el-button type="primary" round @click="openNoPass(false)" v-if="examInfo.auditState==20||examInfo.auditState==30">不通过</el-button>
                        <el-button type="primary" round @click="approved(30, true)" v-if="(examInfo.auditState==20||examInfo.auditState==40) && !nextBtnDisabled">通过并审核下一个</el-button>
                        <el-button type="primary" round @click="openNoPass(true)" v-if="(examInfo.auditState==20||examInfo.auditState==30) && !nextBtnDisabled">不通过并审核下一个</el-button>

                        <el-button type="primary" round @click="jumpNext()" v-if="!nextBtnDisabled">下一个</el-button>
                    </template>
                </div>
            </div>
        </div>
        <!-- 图片预览 -->
        <!--<transition name="fade">-->
        <!--    <div class="previewImg" v-if="dialogVisible" @click="dialogVisible=false">-->
        <!--        <img class="image" :src="previewSrc" alt="">-->
        <!--    </div>-->
        <!--</transition>-->
      <el-dialog
          title="提示"
          :visible.sync="noPassDialogShow"
          width="40%"
          top="5%"
          :center="true"
          :before-close="closeAuditDialog"
      >
        <div style="height: 250px">
          <div class="ovy-a">
            <el-form
                label-width="80px"
                :model="$data"
                class="docsDialog"
            >
              <el-form-item >
                请选择不通过理由（其它请手动输入）
              </el-form-item>
              <el-form-item >
                <el-select
                    v-model="auditReason"
                    placeholder="请选择"
                    @change="generatorAuditMsg"
                    filterable
                    allow-create
                    clearable
                >
                  <el-option
                      v-for="item in reasonList"
                      :key="item.applyReason"
                      :label="item.applyReason"
                      :value="item.applyReason"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item >
                <el-checkbox v-model="sendMsg" />
                <span style="margin-left: 5px">消息提醒</span>
              </el-form-item>
              <el-form-item v-if="sendMsg" >
                消息内容
              </el-form-item>
              <el-form-item v-if="sendMsg" >
                <el-input v-model="message" type="textarea"
                                      size="small"
                                      placeholder="请输入消息内容" />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span
            slot="footer"
            class="dialog-footer"
            style="display: flex; justify-content: center"
        >
        <div>
          <el-button @click="closeAuditDialog">取消</el-button>
          <el-button class="bgc-bv" @click="noPassAudit">确定</el-button>
        </div>
      </span>
      </el-dialog>
    </div>
</template>

<script>
    export default {
        name:"studentDetails",
        data(){
            return{
              noPassDialogShow: false,
                // 学员信息（考试信息）
                examInfo:{},
                // 学员信息 （个人信息）
                personInfoList:[],
                // 学员信息（材料信息）
                materialInfoList:[],
                // 培训任务id（路由传参而来）
                projectId:"",
                channelId:null,
                contactUser:null,
                // 学员id（路由传参而来）
                userId:"",
                // 从哪个页面来的 （路由传参）
                origin:"",
                // 总数
                total: 0,
                // 审核不通过理由
                auditReason:"",
                next: false,
                sendMsg: true,
                message: "",
                // 预览图片的链接
                // previewSrc:"",
                // 对话框可见性
                dialogVisible:false,
                index: 1,
                searchForm: null,
                type: null,
                projectName: null,
                reasonList: [
                    // "上传的免冠证件照片不合格，需要上传电子版白底照片，不能上传翻拍的纸质照片",
                    // "请上传毕业证书上有学校盖章的主页，没有毕业证书的需要单位开证明然后上传",
                    // "重新提交毕业证书，拍照时将毕业证的四角拍全",
                ],
              isStatistics:false,
                // 申报条件 - 下拉数据
                // declarationConditions: [],
            }
        },
        methods:{
            getValueName(val){
                let name = ''
                this.$post("/biz/apply/user/selectDeclarationCondition",{projectId: this.projectId},3000,true,6).then((ret) => {
                    // this.declarationConditions = ret.data;
                    console.log(ret.data.find(item=>item.conditionCode == val).description)
                    name = ret.data.find(item=>item.conditionCode == val).description

                });
                console.log('name',name)
            },
            initNextProperties() {
              this.projectId=this.$route.query.projectId;
              this.projectName=this.$route.query.projectName;
              this.userId=this.$route.query.userId;
              this.contactUser=this.$route.query.contactUser;
              this.index = this.$route.query.index ? parseInt(this.$route.query.index) : null;
              this.total = this.$route.query.total ? parseInt(this.$route.query.total) : null;
              this.channelId = this.$route.query.channelId ? parseInt(this.$route.query.channelId) : null;
              this.type = this.$route.query.type;
              this.isStatistics = this.$route.query.isStatistics?this.$route.query.isStatistics:false;
            },
            // 初始化数据
            initData(){
                // 必须先赋值路由参数
                this.origin=this.$route.query.origin;
                this.isStatistics=this.$route.query.isStatistics?this.$route.query.isStatistics:false;;
                // if(this.origin==="学员报名统计"){
                //     this.projectId=this.$route.query?.row?.projectId||"";
                // }
                this.searchForm =this.$route.query.searchForm?JSON.parse(this.$route.query.searchForm):null;
                // 获取学员详情信息
                this.getExamInfo();
                // 获取个人信息
                this.getPersonInfo();
                // 获取学员材料信息
                this.getMaterialInfo();
                // 获取不通过理由列表
                this.getNotPassList()
            },
            // 获取不通过理由列表
            getNotPassList(){
                this.$post("/biz/ct/user/ctProjectApplyReasonList",{}).then(res=>{
                    this.reasonList = res.data;
                }).catch(e=>{
                    console.error("获取不通过理由列表信息出错",e);
                });
            },
            // 获取学员详情信息（考试信息）
            getExamInfo(){
                const {projectId,userId,}=this;
                this.$post("/biz/ct/user/ctProjectUserInfo",{
                    projectId,
                    userId
                }).then(res=>{
                    this.examInfo = res.data;
                    this.generatorAuditMsg();
                }).catch(e=>{
                    console.error("获取学员考试信息出错",e);
                });
            },
          generatorAuditMsg() {
            let dt = new Date(Date.parse(this.examInfo.createTime));
            let fullYear = dt.getFullYear();
            let fullMonth = dt.getMonth() + 1;
            let fullDate = dt.getDate();
            this.message = this.examInfo.userName + "学员，您于" + fullYear + "-"
                + fullMonth + "-" + fullDate + "报名的" + this.examInfo.projectName + "，" + (this.auditReason || "")
                + "，请登录平台修改相应的信息。"
          },
            // 获取学员详情信息（个人信息）
            getPersonInfo(){
                const {projectId,userId}=this;
                this.$post("/biz/ct/user/info",{
                    projectId,
                    userId
                }).then(res=>{
                    this.personInfoList=res.data || [];
                    this.$post("/biz/apply/user/selectDeclarationCondition",{projectId: this.projectId},3000,true,6).then((ret) => {
                        // this.declarationConditions = ret.data;
                        this.personInfoList.forEach((e,i)=>{
                            if(e.attributeType == 8){
                                e.value = ret.data.find(item=>item.conditionCode == e.value).description
                            }
                        })

                    });

                }).catch(e=>{
                    console.error("获取学员个人信息出错",e);
                });
            },
            closeAuditDialog(){
              this.noPassDialogShow = false;
              this.auditReason = "";
              this.sendMsg = true;
            },
            // 获取学员详情信息（材料信息）
            getMaterialInfo(){
                const {projectId,userId}=this;
                this.$post("/biz/ct/user/material",{
                    projectId,
                    userId
                }).then(res=>{
                    this.materialInfoList=res.data || [];
                }).catch(e=>{
                    console.error("获取学员材料信息出错",e);
                });
            },
            // 预览图片
            // preview(item){
            //     this.previewSrc=item.value;
            //     this.dialogVisible=true;
            // },
            openNoPass(next) {
                this.next = next;
                this.generatorAuditMsg();
                this.noPassDialogShow = true;
            },
            jumpNext() {
                this.goNext(true)
            },
            jumpPrev(){
                this.goPrev(true)
            },
            // 审核通过或者不通过
            approved(auditState, next){
                this.next = next;
                // auditState -  30:通过  40:未通过
                const {projectId,userId,auditReason, sendMsg, message}=this;
                this.$post("/biz/ct/user/ctProjectUserAudit",{
                    projectId,
                    userId,
                    auditReason,
                    auditState,
                    sendMsg,
                    message
                }).then(()=>{
                    this.$message.success("操作成功");
                    this.noPassDialogShow = false;
                    if (this.next) {
                      this.goNext();
                    } else {
                      let path = "/web/institutionalManagement/channelManagement/studentManagement";
                      let query = {};
                      if (this.type === "depart") {
                        path = "/web/studentManagement";
                        query.projectId = this.projectId;
                        query.projectName = this.projectName;
                      } else if (this.type === "departChannel") {
                        path = "/web/institutionalManagement/channelManagement/studentList";
                        query.projectId = this.projectId;
                        query.channelId = this.channelId;
                        query.projectName = this.projectName;
                        query.contactUser = this.contactUser;
                      }
                      this.$router.push({
                        path: path,
                        query: query
                      });
                    }
                }).catch(e=>{
                    console.error("审核出错",e);
                });
            },
            noPassAudit() {
              if (!this.auditReason || !this.auditReason.trim()) {
                this.$message.error("请输入不通过理由");
                return;
              }
              if (this.auditReason.length > 400) {
                this.$message.error("不通过理由不能超过400字");
                return;
              }
              if (this.sendMsg) {
                if (!this.message || !this.message.trim()) {
                  this.$message.error("发送消息提醒需要提供消息信息");
                  return;
                }
              }
              this.approved(40, this.next);
            },
            // 审核不通过
            noPass(next){
                this.$prompt('请输入不通过理由', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /.+/,
                    confirmButtonClass:"bgc-bv",
                    inputErrorMessage: '请输入不通过理由'
                }).then(({ value }) => {
                    this.auditReason=value;
                    this.approved(40, next);
                });
            },
            goPrev(justPrev) {
              let url = "/biz/ct/user/ctProjectChannelUserList";
              let params = {
                pageNum: this.index - 1,
                pageSize: 1,
                ...this.searchForm
              }
              if (this.type === "departChannel") {
                url = "/biz/ct/channel/pageListApply";
                params.projectId = this.projectId;
                params.channelId = this.channelId;
              } else if (this.type === "depart") {
                if(params.channelId === "") delete params.confessMark;
                if(params.channelId === "noId") delete params.channelId;
                params.projectId = this.projectId;
                url = "/biz/ct/user/ctProjectUserList";
                if (this.searchForm && this.searchForm.auditState && !justPrev) {
                  params.pageNum++;
                }
              } else if (this.type === "channel" && this.searchForm && this.searchForm.auditState
                  && !justPrev) {
                params.pageNum++;
              }

              this.$post(url, params).then(res=>{
                if (res.status == '0' && res.data.list && res.data.list.length === 1) {
                  this.projectId = res.data.list[0].projectId;
                  this.userId = res.data.list[0].userId;
                  this.index = res.data.pageNum * res.data.pageSize;
                  this.total = res.data.total;
                  this.initData();
                } else {
                  this.$message.warning("没有上一个了");
                }
              }).catch(e=>{
                console.error("获取列表数据出错",e);
              });
            },
            goNext(justNext) {
              let url = "/biz/ct/user/ctProjectChannelUserList";
              let params = {
                pageNum: this.index + 1,
                pageSize: 1,
                ...this.searchForm
              }
              if (this.type === "departChannel") {
                url = "/biz/ct/channel/pageListApply";
                params.projectId = this.projectId;
                params.channelId = this.channelId;
              } else if (this.type === "depart") {
                if(params.channelId === "") delete params.confessMark;
                if(params.channelId === "noId") delete params.channelId;
                params.projectId = this.projectId;
                url = "/biz/ct/user/ctProjectUserList";
                if (this.searchForm && this.searchForm.auditState && !justNext) {
                  params.pageNum--;
                }
              } else if (this.type === "channel" && this.searchForm && this.searchForm.auditState
                && !justNext) {
                params.pageNum--;
              }

              this.$post(url, params).then(res=>{
                if (res.status == '0' && res.data.list && res.data.list.length === 1) {
                  this.projectId = res.data.list[0].projectId;
                  this.userId = res.data.list[0].userId;
                  this.index = res.data.pageNum * res.data.pageSize;
                  this.total = res.data.total;
                  this.initData();
                } else {
                  this.$message.warning("没有下一个了");
                }
              }).catch(e=>{
                console.error("获取列表数据出错",e);
              });
            },
        },
        created(){
          this.initNextProperties();
            this.initData();
        },
        computed: {
          nextBtnDisabled: function () {
            return this.total <= this.index;
          },
          prevBtnDisabled: function () {
            console.log(this.index)
            return this.index > 1;
          }
        }
    }
</script>

<style lang="less" scoped>
    .templateList{
        .framePage-body-inner{
            display: flex;
            .framePage-body-inner-item{
                width: 40%;
                min-width: 25rem;
                .title{
                    position: relative;
                    padding-left: 1.25rem;
                    line-height: 1.17rem;
                    margin: 20px 0;
                    &::after{
                        content:"";
                        display:block;
                        width: 0.525rem;
                        height: 1.17rem;
                        position: absolute;
                        left: 0;
                        top: 0%;
                        background-color: #5C6BE8;
                    }
                }
                .examInfo{
                    padding-left:40px;
                    .personInifo-wrap{
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        margin: 10px 0;
                        border: 1px solid #DCDFE6;
                        padding: 5px 10px;
                        border-radius: 4px;
                        .label{
                            width: 6rem;
                            border-right: 1px solid #DCDFE6;
                            padding-right: 10px;
                            flex-shrink: 0;
                        }
                        .value{
                            margin-left:20px;
                            color:#909399;
                        }
                    }
                }
                &:nth-child(2){
                    margin-left:20px;
                    min-width: 43rem;
                }
                .materialInfo{
                    display: flex;
                    flex-wrap: wrap;
                    .materialInfo-item{
                        margin: 10px;
                        cursor: pointer;
                        .img-wrap{
                          text-align: center;
                            width: 17.625rem;
                            height: 8.5rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                            img{
                                display: block;
                                width: auto;
                                height: 100%;
                                margin: 0 auto;
                            }
                        }
                        .text{
                            text-align: center;
                            margin-top: 10px;
                        }
                        .img-2c-wrap{
                            width: 12rem;
                            height: 16rem;
                            border: 1px dashed #c0ccda;
                            border-radius: 4px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .btn-group{
            margin-top: 20px;
            padding-left: 12.5rem;
            .el-button{
                background-color: #5C6BE8;
                border: none;
                &:hover{
                    background-color: #6875ee;
                }
            }
        }
        .previewImg{
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 3000;
            display: flex;
            align-items: center;
            padding: 40px;
            box-sizing: border-box;
            .image{
                display: block;
                max-height: 100%;
                height: auto;
                max-width: 100%;
                width: auto;
                margin: 0 auto;
            }
        }
        .fade-enter-active, .fade-leave-active {
            transition: opacity .2s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      background-color: transparent;
    }
    .docsDialog .el-form-item {
      margin-bottom: 5px;
    }
</style>
